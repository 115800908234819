
$bacground: #0C0D0D;
$box-background: #19191E;
$box-border: #3B3B44;
$text-gray: #808080;
$text-white: #D3D3D3;
$text-soft-white: #c4c4c4;
$low-color: #864427;
$mid-color: #BA8C35;
$high-color: #00AB65;
$even-color: #058793;

.wrapper {
    display: flex;
  }
  .wrapper > div {
    flex: 1 1 auto;
  }

.no-bottom-padding{
    padding-bottom: 0px;
}

.plr-5{
    padding-left: 5px;
    padding-right: 5px;
}
.align-right{
    text-align: right;
}

@media (min-width: 1200px){
    .css-ckgc4n-MuiGrid2-root{
        &.no-bottom-padding{
            padding-bottom: 0px;
        }
    }
}

/* Check box */
.css-1oakzvg-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-1oakzvg-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: $box-border;
}
.css-j204z7-MuiFormControlLabel-root{
    margin-left: 0px !important;
}
.css-1oakzvg-MuiButtonBase-root-MuiCheckbox-root{
    padding:0px !important;
}
svg path{
    color: $text-gray;
}


.ll-dashboard{
    background-color: $bacground;
    min-height: 100vh;
    overflow: hidden;
}

.nav-title{
    color: #ff810d;
    font-weight: 800;
    font-size: larger;
}

.nav-info{
    font-size: 16px;
    padding-left: 10px;
}

.ll-nav,.css-hip9hq-MuiPaper-root-MuiAppBar-root{
    background-color: $high-color !important;  
}

.ll-box{
    border: 2px solid $box-border;
    background-color: $box-background;  
    color: $text-gray;
    min-height: 120px;
    max-height: 300px;
    border-radius: 14px;
    display: flex;
    flex-direction: column;

    &.large{
        max-height: 585px; 
    }

    &.mid{
        max-height: 282px;
        &.bottom-margin{
            margin-bottom: 16px;
        }
    }

    &.small{
        min-height: 150px;
        max-height: 150px;
    }

    

    header{
        margin: 10px 10px 5px 10px;
        border-bottom: 1px solid $box-border;
        padding-bottom: 5px;
        font-weight: 500;
    }

    .section{
        overflow-x: auto;
        overflow-y: auto;
    }

    label{
        padding-bottom: 5px;
        font-size: 14px;
        text-align: left;

        &.ll-patterns{
            color: $text-gray;
            text-align: right;
        }

        &.left-margin{
            margin-left: 10px;
        }
    }

    .ll-jackpot{
        color: $text-soft-white;
        font-size: 46px;
        text-align: right;
        padding: 0 10px 0 10px;
    }

    .number-box{
        text-align: center;
        background-color: #1e2021;
        margin-right: 1px;

        label{
            margin: 0px;
        }
    }

    .outer-circle{
        width: 44px ;
        height: 44px;
        border: 2px solid #0D0C0C;
        border-radius: 50%;
        display: block;
        text-align: center;
        margin:auto;

        &.selected{
            border: 2px solid #058793;
        }

        .inner-circle{
            width: 36px ;
            height: 36px;
            background-color: $bacground;
            border-radius: 50%;
            margin: 4px;
            color: white;

            &.L{background-color: $low-color;}
            &.M{background-color: $mid-color;}
            &.H{background-color: $high-color;}

            .inner-number{
                font-size: 22px;
                padding-top: 4px;
                font-weight: 600;
            }
        }
    }

    .level-stats{
        margin: 0px;
        width: 27px ;
        text-align: left;
    }

    .label-status{
        width: 18px ;
        height: 18px;
        background-color: $bacground;
        text-align: center;
        color: white;
        display: inline-block;
        
        &.L{background-color: $low-color; border:1px solid $low-color;}
        &.M{background-color: $mid-color; border:1px solid $mid-color;}
        &.H{background-color: $high-color; border:1px solid $high-color;}
        &.Even{border:1px solid $even-color;}
        &.Odd{background-color:$even-color; border:1px solid $even-color;}

        &.bar{
            width: -webkit-fill-available;
            height: 8px;
            border-radius: 4px;
        }
    }

    .ll-has-played{
        span{
            color: $text-gray ;
        }
    }

    .ll-patterns{
        font-size: 14px;

        .name{ color: $text-gray }
        .progress-bar{ padding: 0px 5px 0px 5px;}
        .percent{
            color: $text-white;
            text-align: right;
            padding-right: 4px;
        }
        .level{
            padding-left: 5px;
            &.L{color: $low-color;}
            &.M{color: $mid-color}
            &.H{color: $high-color}
        }
        .MuiLinearProgress-root{
            height: 14px;
            background-color: $bacground;
            border-radius: 6px;

            &.L{  .MuiLinearProgress-bar{border-radius: 8px; background: $low-color;}}
            &.M{  .MuiLinearProgress-bar{border-radius: 8px; background: $mid-color;}}
            &.H{ .MuiLinearProgress-bar{border-radius: 8px; background: $high-color;}}
        }
    }

    .ll-weekly{
        font-size: 14px;
        
        .name{ 
            color: gray;
            &.today{
                color:$text-white;
            }
        }
        .level-box{
            height: 16px;

            &.L{background-color: $low-color;}
            &.M{background-color: $mid-color;}
            &.H{background-color: $high-color;}
        }
        .level{
            &.low{color: $low-color;}
            &.mid{color: $mid-color;}
            &.high{color: $high-color;}
        }
    }

    .ll-column-levels{
        font-size: 14px;
        
        .name{ color: $text-gray; }

        .level{
            font-size: 14px;
            &.L{color: $low-color;}
            &.M{color: $mid-color}
            &.H{color: $high-color}
        }
    }

    .ll-odd-even{
        font-size: 14px;
        
        .name{ color: $text-gray; }


        .Even{
            height: 14px;
            border: 1px solid $even-color;
            border-radius: 7px;
        }
        .Odd{
            height: 14px;
            border: 1px solid $even-color;
            background-color: $even-color;
            border-radius: 7px;
        }

        .level{
            font-size: 14px;
            &.L{color: $low-color;}
            &.M{color: $mid-color;}
            &.H{color: $high-color;}
        }
    }

    .ll-number-select{
        padding-left: 10px;

        .MuiFormControl-root{
            margin: 2px;
            min-width: 48px;

            label{
                color: $text-gray;
                padding: 0px;
                margin: 0px;
            }

            .MuiInputBase-root{
                &.css-1dbuez4-MuiInputBase-root-MuiInput-root-MuiSelect-root:after{
                    border-bottom: 1px solid $box-border;
                }

                .MuiSelect-select{
                    color: $text-white;
                    font-size: 14px;
                }
        
                .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon{
                    color: #058793;
                }

            }
        }

        /* Check box */
        .MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary{
            padding: 0px;
            margin-right: 5px;
        }

         /* Button */
        .MuiButtonBase-root.MuiButton-textPrimary{
            background-color: $text-gray;
            color: $text-white;
            width: 100%;
            max-width: 200px;
        }
    }
}