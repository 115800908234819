body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* FONT FACE */
@font-face {
  font-family: 'Conv_Gruppe S';
  src: url('../src/fonts/Gruppe_S/Gruppe\ S.eot');
  src: url('../src/fonts/Gruppe_S/Gruppe\ S.eot') format('embedded-opentype'),
  url('../src/fonts/Gruppe_S/Gruppe S.woff') format('woff'), url('../src/fonts/Gruppe_S/Gruppe S.ttf') format('truetype'), url('../src/fonts/Gruppe_S/Gruppe S.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Conv_Mecha_Bold';
  src: url('../src/fonts/Mecha_Bold/Mecha_Bold.eot');
  src: url('../src/fonts/Mecha_Bold/Mecha_Bold.eot') format('embedded-opentype'),
  url('../src/fonts/Mecha_Bold/Mecha_Bold.woff') format('woff'), url('../src/fonts/Mecha_Bold/Mecha_Bold.ttf') format('truetype'), url('../src/fonts/Mecha_Bold/Mecha_Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Conv_Mecha_Condensed';
  src: url('../src/fonts/Mecha_Condensed/Mecha_Condensed.eot');
  src: url('../src/fonts/Mecha_Condensed/Mecha_Condensed.eot') format('embedded-opentype'),
  url('../src/fonts/Mecha_Condensed/Mecha_Condensed.woff') format('woff'), url('../src/fonts/Mecha_Condensed/Mecha_Condensed.ttf') format('truetype'), url('../src/fonts/Mecha_Condensed/Mecha_Condensed.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Conv_Mecha';
  src: url('../src/fonts/Mecha/Mecha.eot');
  src: url('../src/fonts/Mecha/Mecha.eot') format('embedded-opentype'),
  url('../src/fonts/Mecha/Mecha.woff') format('woff'), url('../src/fonts/Mecha/Mecha.ttf') format('truetype'), url('../src/fonts/Mecha/Mecha.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}


/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #0E1011;
}

*::-webkit-scrollbar-thumb {
  background-color: #1e2021;
  border-radius: 20px;
  // border: 1px solid #058793;
}